$(function () {
	APP.init();
	lozadInit();
	MenuSpyInit();
	hoverInit();
	fancyboxInit();
	countUpInit();
	accordionCollapse();
	changeMaps();
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			body.removeClass('overflow-hidden');
			mobileWrap.removeClass('open');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let mainMenuLeft = $(".header-left .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav",
			mobileMethod: "prependTo",
			desktopWrapper: ".header-left .header-bot .navbar-nav",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
		let mainMenuRight = $(".header-right .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav",
			mobileMethod: "appendTo",
			desktopWrapper: ".header-right .header-bot .navbar-nav",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
		let buttonBook = $("header .button-book").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "insertAfter",
			desktopWrapper: ".header-right .navbar-nav",
			desktopMethod: "insertAfter",
			breakpoint: 1199.98,
		});
		let buttonLanguage = $("header .button-language").mapping({
			mobileWrapper: ".header-right .header-top",
			mobileMethod: "prependTo",
			desktopWrapper: ".header-left .header-top",
			desktopMethod: "appendTo",
			breakpoint: 1199.98,
		});
		// let serviceOther = $(".service-detail-page .service-other").mapping({
		// 	mobileWrapper: "main",
		// 	mobileMethod: "appendTo",
		// 	desktopWrapper: ".service-detail-page .consultation-section",
		// 	desktopMethod: "insertAfter",
		// 	breakpoint: 0,
		// });
	},
	toggleDropdown: () => {
		var dropdownToggle = $(".dropdown-toggle"),
			dropdownMenu = $(".dropdown-menu");
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest('.dropdown').length) {
				$('.dropdown .dropdown-menu').slideUp();
			}
		});
	},
	toggleLanguage: () => {
		$('.button-language .language-toggle').on('click', function () {
			$(this).parents('.button-language').toggleClass('active');
		})
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest('.button-language').length) {
				$('.button-language').removeClass('active');
			}
		});
	},
	toggleMenuMobile: () => {
		buttonMenu.on('click', function () {
			$(this).toggleClass('open');
			mobileWrap.toggleClass('open');
			backdrop.toggleClass('open');
			body.toggleClass('overflow-hidden');
		});
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest('.mobile-wrap').length && !$(e.target).closest('#buttonMenu').length) {
				buttonMenu.removeClass('open');
				mobileWrap.removeClass('open');
			}
		});

		$('.main-menu .children-toggle').on('click', function () {
			$(this).toggleClass('active').next().slideToggle().parents('li').siblings().find('.children-toggle').removeClass('active').next().slideUp();
		});
	},
	toggleSearch: () => {
		buttonSearch.on('click', function () {
			searchWrap.addClass('open');
			body.addClass('overflow-hidden');
			$('.searchbox input').focus();
		});
		// Additional code to close the popup by clicking anywhere outside it
		$(document).on('click', function (e) {
			if (!$(e.target).closest('.searchbox').length && !$(e.target).closest('.button-search').length) {
				searchWrap.removeClass('open');
			}
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleLanguage();
		APP.toggleMenuMobile();
		APP.toggleSearch();
	}
}

var defaultSettingSwiper = {
	preventInteractionOnTransition: true,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
}

const homeBannerSlider = new Swiper(`.home-banner-slider .swiper`, {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	slidesPerView: 1,
	navigation: {
		prevEl: `.home-banner-slider .button-prev`,
		nextEl: `.home-banner-slider .button-next`,
	},
	pagination: {
		el: `.home-banner-slider .swiper-pagination`,
		clickable: true,
	},
});

const homeServiceSlider = new Swiper(`.home-service-slider .swiper`, {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	slidesPerView: 'auto',
	navigation: {
		prevEl: `.home-service-slider .button-prev`,
		nextEl: `.home-service-slider .button-next`,
	},
	pagination: {
		el: `.home-service-slider .swiper-pagination`,
		clickable: true,
	},
});

const homeFeaturedSlider = new Swiper(`.featured-slider .swiper`, {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 2,
	grid:{
		rows: 2,
		fill: 'row',
	},
	navigation: {
		prevEl: `.featured-slider .button-prev`,
		nextEl: `.featured-slider .button-next`,
	},
	pagination: {
		el: `.featured-slider .swiper-pagination`,
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 32,
		},
	},
});

const homeNewsSlider = new Swiper(`.news-slider .swiper`, {
	...defaultSettingSwiper,
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	navigation: {
		prevEl: `.news-slider .button-prev`,
		nextEl: `.news-slider .button-next`,
	},
	pagination: {
		el: `.news-slider .swiper-pagination`,
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 16,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 24,
		},
	},
});

$('.two-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-two-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-two-${index}`);
	$(this).find('.button-prev').addClass(`prev-two-${index}`);
	$(this).find('.button-next').addClass(`next-two-${index}`);
	const twoSlider = new Swiper(`.swiper-two-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.two-slider .prev-two-${index}`,
			nextEl: `.two-slider .next-two-${index}`,
		},
		pagination: {
			el: `.two-slider .pagination-two-${index}`,
			clickable: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1280: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
		},
	});
});

$('.three-slider').each(function (index) {
	var centerSlider = {
		centeredSlides: false,
	}
	if($(this).hasClass('is-centered')) {
		centerSlider = {
			centeredSlides: true,
		}
	}
	$(this).find('.swiper').addClass(`swiper-three-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
	$(this).find('.button-prev').addClass(`prev-three-${index}`);
	$(this).find('.button-next').addClass(`next-three-${index}`);
	const threeSlider = new Swiper(`.swiper-three-${index}`, {
		...defaultSettingSwiper,
		...centerSlider,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.three-slider .prev-three-${index}`,
			nextEl: `.three-slider .next-three-${index}`,
		},
		pagination: {
			el: `.three-slider .pagination-three-${index}`,
			clickable: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});
});

$('.auto-slider').each(function (index) {
	$(this).find('.swiper').addClass(`swiper-auto-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-auto-${index}`);
	$(this).find('.button-prev').addClass(`prev-auto-${index}`);
	$(this).find('.button-next').addClass(`next-auto-${index}`);
	const autoSlider = new Swiper(`.swiper-auto-${index}`, {
		...defaultSettingSwiper,
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 'auto',
		navigation: {
			prevEl: `.auto-slider .prev-auto-${index}`,
			nextEl: `.auto-slider .next-auto-${index}`,
		},
		pagination: {
			el: `.auto-slider .pagination-auto-${index}`,
			clickable: true,
		},
		breakpoints: {
			576: {
				spaceBetween: 16,
			},
			1280: {
				spaceBetween: 32,
			},
		},
	});
});

// const answerSlider = new Swiper(`.answer-slider`, {
// 	...defaultSettingSwiper,
// 	speed: 1000,
// 	spaceBetween: 32,
// 	slidesPerView: 1,
// 	allowTouchMove: false,
// 	navigation: {
// 		prevEl: `.answer-slider .button-answer-prev`,
// 		nextEl: `.answer-slider .button-answer-next`,
// 	},
// 	on: {
// 		slideChange: function (slide) {
// 			if (slide.realIndex != 0) {
// 				$('.box-utilize').slideUp();
// 			} else{
// 				$('.box-utilize').slideDown();
// 			}
// 		},
// 	},
// });

// const surveySlider = new Swiper(`.survey-slider`, {
// 	...defaultSettingSwiper,
// 	speed: 1000,
// 	spaceBetween: 32,
// 	slidesPerView: 1,
// 	allowTouchMove: false,
// 	autoHeight: true,
// 	navigation: {
// 		prevEl: `.survey-slider .button-survey-prev`,
// 		nextEl: `.survey-slider .button-survey-next`,
// 	},
// });

// $('.button-survey-start-over').on('click', function () {
// 	answerSlider.slideTo(0);
// 	surveySlider.slideTo(0);
// 	$('.box-answer input[type="radio"]').prop('checked', false);
// });

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function MenuSpyInit() {
	var elm = document.querySelector('#menu-spy');
	var ms = new MenuSpy(elm, {
		activeClass: 'active',
		threshold: widthWindow >= 1200 ? 140 : 50,
		callback: function (menu) {
			// scroll left menu
			// $(elm).animate({
			// 	scrollLeft: $('#menu-spy li.active').offset().left - 20
			// }, 500);
		}
	});
}

function hoverInit() {
	if(widthWindow >= 1280) {
		$('.why-us-item').on('mouseenter', function () {
			$(this).find('.content').slideDown();
		}).on('mouseleave', function () {
			$(this).find('.content').slideUp();
		});
	}
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.count-up').each(function (index) {
		$(this).attr('id', `countUp-${index}`)
		const endVal = $(this).data('count');

		const options = {
			separator: '.',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}

function accordionCollapse() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			// .siblings().removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			// .parents('.accordion-item')
			// .siblings().find('.accordion-content').slideUp()
	});

	$('.btn-toggle-accordion').on('click', function () {
		if($(this).hasClass('button-expand')) {
			$('.accordion-content').slideDown();
			$('.accordion-item').addClass('active');
			$(this).hide();
			$('.btn-toggle-accordion.button-collapse').show();
		} else {
			$('.accordion-content').slideUp();
			$('.accordion-item').removeClass('active');
			$(this).hide();
			$('.btn-toggle-accordion.button-expand').show();
		}
	});
}

function changeMaps(){
	$('.contact-list li').on('click', function () {
		var dataIframe = $(this).data('iframe');
		$(this).addClass('active').siblings().removeClass('active');
		$('.iframe-maps iframe').attr('src', dataIframe);
	});
}
